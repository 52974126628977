@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

body {
    background-color: #000000;
    padding: 0;
    margin: 0;
    border: none;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
}

h1 {
    font-family: Raleway;
    color: #ffffff;
    font-size: 60px;
    font-weight: 750;
    padding: 15px;
    margin: 15px;
}

h2 {
    font-family: Raleway;
    color: #e0f5f8;
    font-size: 50px;
    font-weight: 600px;
    padding: 10px;
    margin: 10px;
}

h3 {
    font-family: Nunito;
    color: #23a4ad;
    font-size: 22.5px;
    font-weight: 700;
    padding: 10px;
    margin: 10px;

    transition-property: transform;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    transform: scale(1);

    &:hover {
        transform: scale(1.1);
    }
}

p {
    font-family: Nunito;
    color: #85cfd3;
    font-size: 20px;
    font-weight: 600;
    padding: 10px;
    margin: 10px;
}

h1, h2, h3, p {
    text-align: center;
    padding-top: 25px;
    padding-bottom: 25px;

    &.alignLeft {
        text-align: left;
    }

    &.alignRight {
        text-align: right;
    }
}

a {
    color: inherit;
    text-decoration: none !important;
}

button {
    opacity: 1.0;
    background-color: transparent;
    padding: 10px;
    margin: auto;
    width: 100%;
    border: none;
}

div {
    margin: auto;
    padding: none;
    border: none;
    display: block;

    &.dividerLine {
        width: 75%;
        height: 5px;
        background-color: #05edf5;
        $verticalMargin: 2vw;
        margin-top: $verticalMargin;
        margin-bottom: $verticalMargin;
    }

    &#backArrowWrapper {
        position: absolute;
        top: 0;
        left: 0;
    
        transition-property: transform;
        transition-duration: 0.75s;
    
        &:hover {
            transform: scale(1.1);
        }
    }
}

img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    height: auto;

    &.profilePicture {
        object-fit: cover;
        border-radius: 50%;
        $widthAndHeight: 10vh;
        height: $widthAndHeight;
        width: $widthAndHeight;
    }
}

table {
    table-layout: fixed;
    width: 100%;
}

form {
    width: 100%;
}

input {
    margin: auto;
    display: block;
    border-radius: 7px;
    background-color: #2c2b2b;
    border: 2px solid #375458;
    width: 75%;
    padding: 5px;
    margin-bottom: 5vh;
    margin-top: 1vh;
    outline: none !important;
    color: white;
    font-family: Nunito;

    transition-property: border, background-color, transform;
    transition-duration: 0.5s;
    transition-timing-function: ease;

    &:focus {
        transform: scale(1.05);
        background-color: #3b3b3b;
        border: 2px solid #05edf5;
    }

    &.submit {
        border: none;
        background-color: transparent;
        color: #23a4ad;
        font-size: 25px;
        font-weight: 700;

        transition-property: transform !important;
        transition-duration: 0.5s !important;
        transition-timing-function: ease;

        &:hover {
            transform: scale(1.1);
        }
    }

    &.radio {
        margin-top: 5px;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
        height: 2.5vh;
    }
}

label {
    
    color: #85cfd3;
    font-size: 17px;
    font-family: Nunito;
    font-weight: 600;

    &.nextToRadio {
        margin-left: 0;
    }
}

textarea {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5vh;
    margin-top: 0;
    display: block;
    border-radius: 7px;
    background-color: #2c2b2b;
    border: 2px solid #375458;
    width: 75%;
    padding: 5px;
    outline: none !important;
    color: white;
    font-family: Nunito;

    transition-property: border transform;
    transition-duration: 0.75s;
    transition-timing-function: ease;

    &:focus {
        border: 2px solid #05edf5;
        transform: scale(1.05)
    }
}

.rounded {
    display: block;
    border-radius: 50%;
}

.noVerticalSpacing {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.growOnHover {
    transition-property: transform;
    transition-duration: 0.5s;

    &:hover {
        transform: scale(1.1);
    }
}

.centered {
    margin-left: auto;
    margin-right: auto;
    display: block;
}