div.todaysQuestionWrapper {
    border-radius: 20px;
    background-color: #171b1b;
    width: 80%;
    $horizontalPadding: 2vw;
    padding-left: $horizontalPadding;
    padding-right: $horizontalPadding;
    border: #ffffff solid 4px;
    overflow-y: hidden;
}

div.questionResponseWrapper {
    width: 100%;
    border-radius: 20px;
    height: min-content;
    background-color: #1c2221;
    border: #d6e4e3 solid 3px;
    margin-bottom: 1vh;
}

h3.greyedOut {
    transition-property: color transform;
    transition-duration: 0.5s;
    color: grey;
}

div#yourResponseWrapper {
    max-height: 0;

    transition-property: max-height;
    transition-duration: 1s;
    transition-timing-function: ease;

    &.shown {
        max-height: 500px;
    }
}

div#pollOptionsWrapper {
    transition-property: margin-left;
    transition-duration: 1s;
    transition-timing-function: ease;
    max-width: 50%;
    margin-left: 25%;

    &.afterSubmission {
        margin-left: 0;
    }
}

input#pollSubmit {
    transition-property: opacity;
    transition-duration: 1s;
    transition-timing-function: ease;

    &.hidden {
        opacity: 0;
    }
}

div#pieChartWrapper {
    opacity: 0;
    transition-property: opacity;
    transition-duration: 1s;
    transition-timing-function: ease;
    width: 400px;
    left: 25%;
    top: 950px;
    position: absolute;
    visibility: hidden;

    &.shown {
        visibility: visible;
        opacity: 1;
    }
}

div#todaysQuestionPieWrapper {
    transition-property: max-height;
    transition-duration: 0.5s;
    height: 800px;
    max-height: 500px;
    
    &.extended {
        max-height: 800px;
    }
}

div.filterByWrapper {
    background-color: #0f0f0f;
    border-radius: 20px;
    margin: 1px;
    margin-bottom: 10px !important;
    margin-top: 3px !important;
    border: 3px solid #3f3f3f;

    transition-property: background-color border;
    transition-duration: 0.75s;
    transition-timing-function: ease;

    &:hover {
        background-color: #202020;
        border: 3px solid #858383;
    }

    &.activeMode {

        //marked as !important so they are not overrided by hover
        background-color: #303030 !important;
        border: 3px solid white !important;
    }
}

.noHorizontalSpacing {
    padding-right: 0;
    padding-left: 0;
    margin-right: 0;
    margin-left: 0;
}